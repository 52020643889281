import React from 'react'
import Icon from '../../shared/icon'
import * as dayjs from 'dayjs'
import 'dayjs/locale/fr'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

/* eslint-disable react/prop-types */
export default function Pepite ({ pepite }) {
  const twitchURL = `https://twitch.tv/${pepite.twitch}`
  const pepiteURL = `/pepites/${pepite.twitch}`
  const formatViewer = (value) => parseInt(value.toFixed(0)).toLocaleString('fr')
  const formatDate = (value) => dayjs().locale('fr').from(dayjs.unix(value), true)

  return <article className="flex flex-col">
    <div className="relative bg-black bg-opacity-30 rounded-md">
      <div className="transition-transform ease-in-out transform hover:translate-x-1 hover:-translate-y-1">
        <a href={twitchURL} target="_blank" rel="noreferrer">
          <div className="relative rounded-md">
            <img className="image rounded-md" alt={pepite.stream.title} src={pepite.thumbnail} width={854} height={480} loading="lazy" />
          </div>

          <div className="absolute top-0 left-0 m-1">
            <p className="flex items-center text-xs rounded text-white bg-red-500 py-0.5 px-1">
              <Icon name="users" className="w-3.5 h-3.5 inline mr-1"/>
              {formatViewer(pepite.stream.viewers)}
            </p>
          </div>

          <div className="absolute bottom-0 left-0 m-1">
            <div className="flex items-center text-xs rounded text-white bg-gray-500 py-0.5 px-1">
              <Icon name="clock" className="w-3.5 h-3.5 inline mr-1"/>
              {formatDate(pepite.stream.started_at)}
            </div>
          </div>
        </a>
      </div>
    </div>

    <div className="flex flex-nowrap pt-2">
      <div className="flex-grow-0 flex-shrink-0">
        <a href={pepiteURL}>
          <figure title={pepite.display_name}>
            <img className="block rounded-xl shadow" alt={pepite.display_name} width="50" height="50" src={pepite.avatar} />
          </figure>
        </a>
      </div>

      <div className="flex-grow min-w-0 w-full pl-2">
        <a className="pb-1" href={twitchURL}>
          <h3 className="text-gray-50 text-sm font-semibold truncate" title={pepite.stream.title}>
            {pepite.stream.title}
          </h3>
        </a>

        <a href={pepiteURL}>
          <p className="text-gray-300 hover:text-blue-400 text-sm truncate">
            {pepite.display_name}
          </p>
        </a>

        <p className="text-gray-300 text-sm truncate">
          {pepite.stream.category}
        </p>
      </div>
    </div>
  </article>
}
