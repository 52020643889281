import React, { Fragment, useState } from 'react'
import { createPortal } from 'react-dom'
import { Dialog, Transition } from '@headlessui/react'
import Icon from './icon'

// eslint-disable-next-line react/prop-types
function MobileButton ({ title, callback }) {
  return createPortal(<a onClick={callback}
            className="block mt-1 px-3 py-2 rounded-md text-base font-medium text-gray-500 dark:text-gray-200 hover:text-gray-100 hover:bg-gray-600 cursor-pointer">
    {title}
  </a>, document.getElementById('menu-mobile-button'))
}

// eslint-disable-next-line react/prop-types
function SidebarButton ({ title, callback }) {
  return <a onClick={callback}
            className="self-center order-5 md:order-0 text-center md:mt-1 sidebar-link cursor-pointer">
    <Icon name="dashboard" className="w-5 h-5 mr-1"/>
    <span className="md:mx-4 block text-sm md:text-base font-light md:font-medium">{title}</span>
  </a>
}

// eslint-disable-next-line react/prop-types
function SpriteSVG ({ sprite, name, className }) {
  return <svg className={className}>
    <use xlinkHref={`${sprite}#${name}`} />
  </svg>
}

// eslint-disable-next-line react/prop-types
function MenuLinksGroup ({ title, children }) {
  return <div>
    <h4 className="px-4 pt-2 uppercase text-xs font-light text-gray-600 dark:text-gray-100">{title}</h4>
    <div className="grid grid-cols-2 px-2 gap-2 py-2">
      {children}
    </div>
  </div>
}

// eslint-disable-next-line react/prop-types
function MenuLink ({ name, url, target = '_self', children }) {
  return <a href={url} target={target} className="bg-white dark:bg-gray-500 dark:text-gray-50 border-1 border-gray-200 hover:bg-blue-400 hover:text-white dark:hover:bg-blue-400 dark:hover:text-white text-center p-3 rounded shadow">
    {children}
    <h5>{name}</h5>
  </a>
}

// eslint-disable-next-line react/prop-types
export default function Menu ({ title, spritePath }) {
  const [open, setOpen] = useState(false)

  return <>
    <SidebarButton title={title} callback={() => setOpen(v => !v)} />
    <MobileButton title={title} callback={() => setOpen(v => !v)} />
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-30 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-gray-600 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-xl sm:w-full sm:p-6">
              <div>
                <div className="block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white dark:bg-gray-700 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="mt-3 text-center">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    { title }
                  </Dialog.Title>
                  <div className="grid grid-cols-1 lg:grid-cols-2 px-2 gap-4 py-2">
                    <MenuLinksGroup title="Gestion du planning">
                      <MenuLink name="Réserver un créneau" url="/hub/events?modal_open=true">
                        <SpriteSVG sprite={spritePath} name="plus" className="w-6 h-6 my-2" />
                      </MenuLink>
                      <MenuLink name="Gérer mes créneaux" url="/hub/events">
                        <SpriteSVG sprite={spritePath} name="calendar" className="w-6 h-6 my-2" />
                      </MenuLink>
                    </MenuLinksGroup>
                    <MenuLinksGroup title="Stream Squad">
                      <MenuLink name="Ajouter une squad" url="/hub/stream_squads?modal_open=true">
                        <SpriteSVG sprite={spritePath} name="plus" className="w-6 h-6 my-2" />
                      </MenuLink>
                      <MenuLink name="Liste des squads" url="/hub/stream_squads">
                        <SpriteSVG sprite={spritePath} name="collection" className="w-6 h-6 my-2" />
                      </MenuLink>
                    </MenuLinksGroup>
                    {window.pepistreams.ADMIN && <>
                      <MenuLinksGroup title="Administration">
                        <MenuLink name="Dashboard" url="/admin">
                          <SpriteSVG sprite={spritePath} name="presentation-chart-bar" className="w-6 h-6 my-2" />
                        </MenuLink>
                      </MenuLinksGroup>
                      <MenuLinksGroup title="Pépites">
                        <MenuLink name="Ajouter une pépite" url="/admin/pepites?modal_open=true">
                          <SpriteSVG sprite={spritePath} name="plus" className="w-6 h-6 my-2" />
                        </MenuLink>
                        <MenuLink name="Liste des pépites" url="/admin/pepites">
                          <SpriteSVG sprite={spritePath} name="user-group-alt" className="w-6 h-6 my-2" />
                        </MenuLink>
                      </MenuLinksGroup>
                      <MenuLinksGroup title="Catégories de Stream">
                        <MenuLink name="Ajouter une catégorie" url="/admin/games/new">
                          <SpriteSVG sprite={spritePath} name="plus" className="w-6 h-6 my-2" />
                        </MenuLink>
                        <MenuLink name="Liste des catégories" url="/admin/games">
                          <SpriteSVG sprite={spritePath} name="annotation" className="w-6 h-6 my-2" />
                        </MenuLink>
                      </MenuLinksGroup>
                      <MenuLinksGroup title="Badges">
                        <MenuLink name="Ajouter un badge" url="/admin/badges/new">
                          <SpriteSVG sprite={spritePath} name="plus" className="w-6 h-6 my-2" />
                        </MenuLink>
                        <MenuLink name="Liste des badges" url="/admin/badges">
                          <SpriteSVG sprite={spritePath} name="shield-exclamation" className="w-6 h-6 my-2" />
                        </MenuLink>
                      </MenuLinksGroup>
                      <MenuLinksGroup title="Utilisateurs">
                        <MenuLink name="Aperçu des streams" url="/admin/users/last_lives">
                          <SpriteSVG sprite={spritePath} name="chart-square-bar" className="w-6 h-6 my-2" />
                        </MenuLink>
                        <MenuLink name="Liste des utilisateurs" url="/admin/users">
                          <SpriteSVG sprite={spritePath} name="identification" className="w-6 h-6 my-2" />
                        </MenuLink>
                      </MenuLinksGroup>
                      <MenuLinksGroup title="Services Tiers">
                        <MenuLink name="Tâches de fond" url="/admin/sidekiq" target="_blank">
                          <SpriteSVG sprite={spritePath} name="clipboard-list" className="w-6 h-6 my-2" />
                        </MenuLink>
                        <MenuLink name="Fonctionnalités à activer" url="/admin/flipper" target="_blank">
                          <SpriteSVG sprite={spritePath} name="puzzle" className="w-6 h-6 my-2" />
                        </MenuLink>
                      </MenuLinksGroup>
                    </>}
                  </div>
                  <div className="px-2 py-2 text-center text-gray-500 dark:text-gray-200">
                    <p>Savez-vous que la pépinière possède sa propre extension pour navigateur qui vous prévient des lives de nos pépites ?</p>
                    <p className="flex justify-center flex-col md:flex-row">
                      <a className="text-blue-500 dark:text-blue-300" href="https://chrome.google.com/webstore/detail/pepistreamers/clcbhhnmaohbkgndbonenipoiimbafhe?hl=fr&authuser=0">Extension pour Google Chrome</a>
                      <span className="hidden md:block px-2"> | </span>
                      <a className="text-blue-500 dark:text-blue-300" href="https://addons.mozilla.org/fr/firefox/addon/pepistreamers/">Extension pour Mozilla Firefox</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  </>
}
