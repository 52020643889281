import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import frLocale from '@fullcalendar/core/locales/fr'
import * as dayjs from 'dayjs'

import './fullcalendar.css'

function renderEventContent (arg) {
  const previewBackgroundStyle = {
    background: `linear-gradient(135deg, ${arg.event.extendedProps.backgroundColor} 0%, ${arg.event.extendedProps.secondBackgroundColor} 100%)`
  }
  if (arg.event.extendedProps.background !== null) {
    previewBackgroundStyle['--preview'] = `url("${arg.event.extendedProps.background}")`
  }

  const displayGuest = function (guest) {
    return (<p className="guest" title={guest} key={guest}>& {guest}</p>)
  }

  return (
    <>
      <div className="inside flex justify-center absolute z-10 w-full h-full p-3 text-white text-center">
        <div className="infos self-center">
          <h2 className="font-semibold uppercase truncate" title={arg.event.extendedProps.streamer}>
            {arg.event.extendedProps.streamer }
            {arg.event.extendedProps.guest && <div className="text-left">
              {arg.event.extendedProps.guest instanceof String && displayGuest(arg.event.extendedProps.guest)}
              {arg.event.extendedProps.guest instanceof Array && arg.event.extendedProps.guest.map(guest => displayGuest(guest))}
            </div>}
          </h2>
          <hr className="my-2"/>
          <h4 className="text-xs font-normal uppercase" title={arg.event.title}>{arg.event.title}</h4>
        </div>
      </div>
      <div className="preview h-full" style={previewBackgroundStyle} />
    </>
  )
}

// eslint-disable-next-line react/prop-types
export default function Calendar ({ eventsEndpoint }) {
  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin]}
      height='auto'
      initialView={(document.body.clientWidth <= 1140) ? 'timeGrid' : 'timeGridWeek'}
      locales={frLocale}
      locale='fr'
      headerToolbar={{
        left: 'today',
        center: 'title',
        right: 'prev,next'
      }}
      buttonText={{
        prev: '<',
        next: '>'
      }}
      titleFormat={
        { day: 'numeric', month: 'long' }
      }
      events={eventsEndpoint}
      eventContent={renderEventContent}
      validRange={{
        start: dayjs().format('YYYY-MM-DD'),
        end: dayjs().add(1, 'M').format('YYYY-MM-DD')
      }}
      firstDay={dayjs().format('d')}
      nowIndicator={true}
      allDaySlot={false}
      slotDuration='00:30:00'
      slotMinTime='08:00:00'
      slotMaxTime='24:00:00'
    />
  )
}
