import React from 'react'
import { createPortal } from 'react-dom'

export default function Counter ({ counter }) {
  const counterText = counter <= 1 ? 'pépite' : 'pépites'

  return createPortal(<>
    <span>{counter} {counterText} actuellement en stream.</span>
  </>, document.getElementById('pepite-live-counter'))
}
