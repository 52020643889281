import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { StaffCard } from './StaffCard';

const query = `
  query GetStaff {
    getStaff {
      name
      avatar
      type
      twitter
      discord
    }
  }
`;

export default function FaqSubPage() {
  const [data, setData] = useState([])

  useEffect(() => {
    axios.post("https://pepistreams.eu/graphql", {query}).then(res => {
      setData(res.data.data?.getStaff ?? [])
    });
  }, [])

  return (
    <div className="grid grid-cols- md:grid-cols-2 gap-3 mt-3 text-gray-500 dark:text-gray-400">
      {data?.map((staff, index) => (
        <StaffCard
          key={index}
          avatar={staff.avatar}
          name={staff.name}
          type={staff.type}
          twitter={staff.twitter}
          discord={staff.discord}
        />
      ))}
    </div>
  )
}
