import React from 'react'
import Pepite from './pepite'

// eslint-disable-next-line react/prop-types
export default function PepiteGroup ({ name, slug, pepites }) {
  const squadURL = `/squad/${slug}`

  return <div className="rounded shadow bg-black bg-opacity-10">
    <div className="flex justify-between align-center p-3">
      <p className="text-white text-xl leading-loose">
        <strong>Squad:</strong> {name}
      </p>
      <a className="border-2 border-white px-4 py-1 text-white hover:text-black hover:bg-white rounded" href={squadURL}>
        Accéder à la squad
      </a>
    </div>
    <div className="p-5 pt-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {/* eslint-disable-next-line react/prop-types */}
      {pepites.map((pepite, index) => <Pepite key={index} pepite={pepite} isLastOfRow={(index + 1) % 3 === 0} />) }
    </div>
  </div>
}
