import React, { useEffect } from 'react'

// eslint-disable-next-line react/prop-types
export default function TwitchPlayerAndChat ({ channel, muted = false }) {
  const playerIdentifier = 'twitch-embed-' + channel

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const player = new Twitch.Embed(playerIdentifier, {
      channel: channel,
      theme: 'dark',

      muted: muted,

      width: '100%',
      height: '100%',

      parent: ['pepistreams.eu']
    })

    return () => { player.destroy() }
  }, [])

  return <div className="aspect-w-16 aspect-h-9" id={playerIdentifier} />
}

export function TwitchChat ({ channel }) {
  return (
    <iframe src={`https://twitch.tv/embed/${channel}/chat?theme=dark&parent=pepistreams.eu&parent=localhost`}
            height="100%"
            width="100%">
    </iframe>
  )
}

export function TwitchPlayer ({ channel }) {
  return (
    <div className="aspect-video">
      <iframe
        src={`https://player.twitch.tv/?channel=${channel}&theme=dark&parent=pepistreams.eu&parent=localhost`}
        height="100%"
        width="100%"
        allowFullScreen="true">
      </iframe>
    </div>

  )
}
