import React, { useEffect, useState } from 'react'
import axios from 'axios'
import useInterval from '../../../hooks/useInterval'
import Pepite from './pepite'
import PepiteGroup from './pepiteGroup'
import Counter from './counter'
import Icon from '../../shared/icon'

export default function Overview () {
  const [lives, setlives] = useState([])
  const [error, setError] = useState(false)

  const updateLivesStatus = () => {
    axios.get('/api/v1/lives/pepites').then((response) => {
      setlives(response.data)
    }).catch(() => {
      setError(true)
    })
  }

  useInterval(updateLivesStatus, 60000)
  useEffect(() => updateLivesStatus(), [])

  const squadList = () => {
    const objectList = lives.map(function (l) {
      return l.squad
    }).flat()
    return [...new Map(objectList.map(item => [item.slug, item])).values()]
  }

  const pepiteInSquad = (squadName) => {
    const pepites = lives.filter(p => p.squad.map(s => s.slug).flat().includes(squadName)).sort(function (a, b) { return a.stream.viewers > b.stream.viewers ? '1' : '-1' })

    return (pepites.length > 1) ? pepites : []
  }

  const pepiteWithoutSquad = () => {
    const pepites = []
    const pepitesToRemove = []

    squadList().forEach(squad => {
      pepitesToRemove.push(pepiteInSquad(squad.slug))
    })

    pepites.push(lives)

    return pepites.flat().filter(p => !pepitesToRemove.flat().includes(p)).sort(function (a, b) { return a.stream.viewers > b.stream.viewers ? '1' : '-1' })
  }

  if (error) {
    return <div className="w-full">
      <Icon name="exclamation" className="w-20 h-20 block mx-auto my-2"/>
      <p className="pb-2 text-center">Récupération des lives impossible</p>
    </div>
  }

  return <>
    {lives.length > 0 && <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7">
        {pepiteWithoutSquad().map((pepite, index) => <Pepite key={index} pepite={pepite}/>)}
      </div>
      {squadList().map((squad, index) =>
        pepiteInSquad(squad.slug).length > 1 &&
        <PepiteGroup key={index} name={squad.title} slug={squad.slug} pepites={pepiteInSquad(squad.slug)}/>
      )}
    </div>}

    {lives.length === 0 && <div>
      <p className="text-center">
        <Icon name="wind" className="w-20 h-20 block mx-auto my-2"/>
        <strong className="pb-2">Ya de l'écho ici, non ? Je crois qu'il va falloir revenir plus tard...</strong>
        <br/>
        En attendant tu peux visiter le site ou venir nous voir sur
        <a className="text-blue-400" href="https://discord.gg/TZeqw6D"> Discord</a> !
      </p>
    </div>}
    <Counter counter={lives.length}/>
  </>
}
