import React, { useState, useEffect } from 'react'
import axios from 'axios'
import useInterval from '../../../hooks/useInterval'
import { TwitchPlayer, TwitchChat } from '../../shared/twitchPlayer';

export default function Player () {
  const [webtv, setWebtv] = useState({ is_live: false })

  const updateWebtvStatus = () => {
    axios.get('/api/v1/lives/webtv').then((response) => {
      setWebtv(response.data)
    })
  }

  useInterval(updateWebtvStatus, 60000)
  useEffect(() => updateWebtvStatus(), [])

  if (!webtv.is_live) { return null }

  return (
    <section className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-0">
      <div className="-mt-36 grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <TwitchPlayer channel="pepinieretwitch" />
            </div>
          </section>
        </div>

        <div className="grid grid-cols-1 gap-4 h-full">
          <section aria-labelledby="section-2-title">
            <div className="rounded-lg bg-white overflow-hidden shadow h-full">
              <TwitchChat channel="pepinieretwitch" />
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}
