import React, { useState } from 'react'
import Icon from './icon'
import Cookies from 'js-cookie'
import { isAuthenticated } from '../../lib/auth'
import { post } from 'axios'

export default function themeSwitch () {
  const hasDarkClass = document.querySelector('html').classList.contains('dark')
  const [theme, setTheme] = useState(hasDarkClass ? 'dark' : 'light')

  const switchTheme = () => {
    const themeToUpdate = theme === 'light' ? 'dark' : 'light'

    if (theme === 'light') {
      document.querySelector('html').classList.add('dark')
    } else {
      document.querySelector('html').classList.remove('dark')
    }

    setTheme(themeToUpdate)

    if (!isAuthenticated()) {
      Cookies.set('theme', themeToUpdate, { expires: 365 * 10 })
    } else {
      post('/api/profil/theme', { theme: themeToUpdate }).catch(console.error)
    }
  }

  return <button onClick={switchTheme} className="w-8 h-8 rounded-lg flex items-center justify-center transition-all duration-300 outline-none">
    {theme === 'light' ? (
      <Icon name='moon-alt' className="text-blue-500 w-5 h-5" />
    ) : (
      <Icon name='sun-alt' className="text-blue-400 w-5 h-5" />
    )}
  </button>
}
