import React, { useEffect, useRef, useState } from 'react'

// eslint-disable-next-line react/prop-types
export default function Alert ({ message, is_notice = true }) {
  const [displayed, setDisplayed] = useState(true)
  const alertRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      alertRef.current.classList.remove('-translate-y-full')
      alertRef.current.classList.remove('opacity-0')
      alertRef.current.classList.add('translate-y-0')
      alertRef.current.classList.add('opacity-100')
    }, 20)
    setTimeout(() => {
      alertRef.current.classList.remove('translate-y-0')
      alertRef.current.classList.remove('opacity-100')
      alertRef.current.classList.add('-translate-y-full')
      alertRef.current.classList.add('opacity-0')
    }, 5800)
    setTimeout(() => setDisplayed(false), 6000)
  }, [])

  if (!displayed) { return null }

  return <div
    ref={alertRef}
    className="my-1 bg-white dark:bg-gray-600 shadow-lg rounded pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden transform transition ease-in-out duration-200 opacity-0 -translate-y-full">
    <div className="p-4">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {/* eslint-disable-next-line camelcase */}
          {is_notice && <svg className="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>}
          {/* eslint-disable-next-line camelcase */}
          {!is_notice && <svg className="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>}
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
            {message}
          </p>
        </div>
      </div>
    </div>
  </div>
}
