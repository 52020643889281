/* eslint no-console: 0 */
import Rails from '@rails/ujs'
import 'controllers'

import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/material_blue.css'

Rails.start()

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
