import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  submit () {
    this.buttonTarget.disabled = true
    this.buttonTarget.textContent = 'Loading...'
    this.buttonTarget.classList.add('bg-gray-200', 'dark:bg-gray-500', 'text-black', 'dark:text-white')
    this.buttonTarget.classList.remove('bg-blue-600', 'hover:bg-blue-700', 'text-white')
  }
}
